<template>
  <b-card title="Upload Box Packing File">
    <b-alert
      variant="danger"
      :show="errorMessage ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ errorMessage }}
        </p>
      </div>
    </b-alert>

    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col lg="3">
          <b-form-group
            label="Box Packing File"
            label-for="packing-file"
          >
            <b-form-file
              id="packing-file"
              v-model="packingFile"
              placeholder="Box packing file"
              accept=".csv"
            />
            <p class="field-remark">
              Stock data with all stock without batch details
            </p>
          </b-form-group>
        </b-col>

        <b-col lg="2">
          <b-button
            variant="primary"
            :disabled="submitting"
            type="submit"
            class="mt-2"
          >
            Submit
            <b-spinner
              v-if="submitting"
              small
              label="Small Spinner"
            />
          </b-button>
        </b-col>
      </b-row>
    </b-form>

    <b-spinner
      v-if="loading"
      small
      label="Small Spinner"
      variant="primary"
    />
    <div v-if="lastUpdatedAt">
      <span>
        Last updated at: {{ formatedDate(lastUpdatedAt, 'DD-MM-YYYY hh:mm:ss A') }}
      </span>
      <a
        target="_blank"
        :href="csvFileLink"
      >
        <feather-icon
          v-b-tooltip.hover="{boundary:'window'}"
          title="Download"
          icon="DownloadIcon"
          class="cursor-pointer mx-1"
          size="22"
        />
      </a>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BForm, BRow, BCol, BFormGroup, BFormFile, BButton, BSpinner, BAlert, VBTooltip,
} from 'bootstrap-vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dateMixin from '@/mixins/dateMixin'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
    BButton,
    BSpinner,
    BAlert,
  },
  mixins: [dateMixin],
  data() {
    return {
      packingFile: null,
      errorMessage: null,
      submitting: false,
      csvFileLink: null,
      lastUpdatedAt: null,
      loading: true,
    }
  },
  created() {
    this.fetchDetails()
  },
  methods: {
    fetchDetails() {
      this.loading = true
      axios.get('/box_packing_data')
        .then(res => {
          this.csvFileLink = res.data.csv_file_link
          this.lastUpdatedAt = res.data.last_updated
          this.loading = false
        })
        .catch(error => {
          this.errorMessage = error?.response?.data?.error || 'Something went wrong'
          this.loading = false
        })
    },
    onSubmit() {
      this.errorMessage = null
      this.submitting = true

      const formData = new FormData()
      formData.append('packing_file', this.packingFile)

      axios.post('/box_packing_data',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          this.csvFileLink = res.data.csv_file_link
          this.lastUpdatedAt = res.data.last_updated
          this.packingFile = null

          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })

          this.submitting = false
        }).catch(error => {
          this.errorMessage = error?.response?.data?.error || 'Something went wrong'
          this.submitting = false
        })
    },
  },
}
</script>
