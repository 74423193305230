<template>
  <div>
    <upload-box-packing-data />
    <upload-stock-data />
  </div>
</template>

<script>
import UploadBoxPackingData from '@/components/OrderUploads/UploadBoxPackingData.vue'
import UploadStockData from '@/components/OrderUploads/UploadStockData.vue'

export default {
  components: {
    UploadBoxPackingData,
    UploadStockData,
  },
}
</script>
